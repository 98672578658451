
import Common from '~/mixins/common'
import _ from 'lodash';
import IconAvatar from '~/components/util/IconAvatar'
import Tabs from '~/components/util/Tabs'
import ClientPartChart from '~/components/common/client/PartChart'
import ClientPartAuth from '~/components/common/client/PartAuth'
import ClientPartCrm from '~/components/common/client/PartCrm'
import ClientPartTicket from '~/components/common/client/PartTicket'
import ClientPartMenu from '~/components/common/client/PartMenu'
import ClientPartLesson from '~/components/common/client/PartLesson'
import ClientPartDate from '~/components/common/client/PartDate'
import ClientReserveSummary from '~/components/common/client/ReserveSummary'
import ClientPartSubsc from '~/components/common/client/PartSubsc'
import EditClientMemo from '~/components/common/client/EditMemo'
import EditClient from '~/components/common/client/Edit'
import StatusChip from '~/components/common/client/StatusChip'
import ClientPartTask from '~/components/common/client/PartTask'
import DocumentInputs from '~/components/util/docs/DocumentInputs'
import GoalTermEdit from '~/components/common/goal_term/Edit'
import GoalTermList from '~/components/common/goal_term/List'
import ClientInvitationChip from '~/components/common/client/InvitationChip'

export default {
  mixins: [Common],
  components: {
    IconAvatar,
    Tabs,
    ClientPartChart,
    ClientPartAuth,
    ClientPartCrm,
    ClientPartTicket,
    ClientPartLesson,
    ClientPartDate,
    ClientReserveSummary,
    ClientPartSubsc,
    ClientPartMenu,
    EditClientMemo,
    EditClient,
    StatusChip,
    ClientPartTask,
    DocumentInputs,
    GoalTermEdit,
    GoalTermList,
    ClientInvitationChip,
  },
  props: {
    client: Object,
  },
  data() {
    return {
      tabIndex: 0,
      tabOptions: [
        {label: "プロフィール", value: 0, disabled: false},
        {label: "レッスン", value: 1, disabled: false},
        {label: "ライフログ", value: 2, disabled: false},
        {label: "3Dスキャン", value: 3, disabled: false},
        {label: "種目", value: 4, disabled: false},
        {label: "ファイル", value: 5, disabled: false},
        {label: "チケット", value: 6, disabled: false},
        {label: "支払い", value: 7, disabled: false},
        {label: "ポイント", value: 8, disabled: false},
        {label: "お知らせ", value: 9, disabled: false},
        {label: "タスク", value: 10, disabled: false},
        {label: "アンケート", value: 11, disabled: false},
      ],

      showPassword: false,
    }
  },
  async mounted() {
    let lessson_ids = []
    if(this.client.prev_lesson_id) lessson_ids.push(this.client.prev_lesson_id)
    if(this.client.next_lesson_id) lessson_ids.push(this.client.next_lesson_id)
    this.getRelatedLessons(lessson_ids)
  },
  watch: {
    client(val){
      console.log("client profile watach")
    },
  },
  computed: {
  },
  methods: {
  },
}
