
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import Common from '~/mixins/common'
import Masters from '~/mixins/masters'
import { scrollToTop } from '~/utils/tool'

export default {
  mixins: [Common,Masters],
  components: {
  },
  props: {
    client: {type: Object, default: null},
    filter: {type: String, default: null},
    showClient: {type: Boolean, default: false},
  },
  data() {
    return {
      // data
      uuid_eq: null,
      freeword: null,
      status_eq_any: [],
      statusOptions: [
        { text: '有効', value: "active" },
        { text: '無効', value: "disactive" },
      ],
      created_at_gteq: null,
      created_at_lteq_end_of_day: null,

      pages: {
        total_count: 1,
        total_pages: 1,
        current_page: 1,
      },
      currentTicketSets: [],

      // edit date limit
      selectedTicketSet: null,
      showDateLimitModal: false,
    }
  },
  async mounted() {
    let self = this
    // filter
    if(self.filter) self.use_type_eq_any = [self.filter]
    this.search()    
  },
  watch: {
    reload_at (val, old) {
      this.search()
    },
    cl_payment_at (val, old) {
      console.log("list cl_payment_at")
      this.search()
    }
  },  
  computed: {
  },
  methods: {
    ableMenus(ticket_set){
      return _.filter(this.menus, (c) => {
        return ticket_set.able_menu_ids.includes(c.id)
      })
    },
    ticketSetEditDateLimit(ticket_set){
      this.selectedTicketSet = _.cloneDeep(ticket_set)
      this.showDateLimitModal = true
    },
    ticketSetUpdateDateLimit(){
      let self = this
      self.$bvModal.msgBoxConfirm("有効期間を更新してよろしいですか？", {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start")
          await httpClient
          .put(`/cmp/floor/ticket_sets/${self.selectedTicketSet.uid}/update_limit_date.json`, {
            use_from: self.selectedTicketSet.use_from,
            use_to: self.selectedTicketSet.use_to,
          })
            .then(async (res) => {
              window.storeCtl.commit("loading/stop")
              if (res.data.status === 'success') {
                window.storeCtl.commit("event/updateClPayment")
                await self.search()
                self.showDateLimitModal = false
                window.storeCtl.commit("alert/setSuccess", "処理しました")
              } else {
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })
    },
    ticketSetCancel(ticket_set){
      let self = this
      
      self.$bvModal.msgBoxConfirm("（注）チケットの無効化をしても支払いは自動払い戻しされません。返金したい場合は別途支払いを払い戻しして下さい。また利用済みのチケットで予約にはチケットを無効化しても影響ありません。", {
        title: '無効化してよろしいですか？',
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start")
          await httpClient
          .delete(`/cmp/floor/ticket_sets/${ticket_set.uid}.json`)
            .then(async (res) => {
              window.storeCtl.commit("loading/stop")
              if (res.data.status === 'success') {
                window.storeCtl.commit("event/updateClPayment")
                await self.search()
                self.$emit("canceled")
                window.storeCtl.commit("alert/setSuccess", "処理しました")
              } else {
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })
    },

    async clear() {
      this.freeword = null
      this.status_eq_any = []
      this.created_at_gteq = null
      this.created_at_lteq_end_of_day = null
      this.order = "desc"
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async autoSearch(){
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async search(){
      let self = this

      // set params
      let params = {
        "q[uid_or_client_name_or_client_uid_cont]": self.freeword,
        "q[created_at_gteq]": self.created_at_gteq,
        "q[created_at_lteq_end_of_day]": self.created_at_lteq_end_of_day,
        "status_eq_any": self.status_eq_any,
        client_id: self.client ? self.client.id : null,
        page: self.pages.current_page,
        order: self.order,
      }
      window.storeCtl.commit("loading/start")
      await httpClient
      .get('/cmp/floor/ticket_sets.json', {params: params})
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.currentTicketSets = res.data.data.ticket_sets
          self.pages = res.data.data.pages
          scrollToTop()
          self.$emit("changeSearch", res.data.data.pages)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },
  }
}
